import { Box, Divider } from '@mui/material';
import React from 'react';
// import './Card.css';

const Card = ({ item }) => {
	return (
		<div className='card'>
			<div className='card-image'>
				<span className='deal-badge'>Deal of the day</span>
				<img
					style={{ height: 250 }}
					src={item?.imgpath} // Replace with your image URL
					alt='Beach'
				/>
				{/* <div className='more-options'>1 More Option Available</div> */}
			</div>
			<div className='card-content'>
				<h3>{item?.title}</h3>
				<p className='subtitle'>
					{item?.tour} {item?.description}
				</p>
				<Divider style={{ border: '1px solid lightgrey', marginBottom: 5 }} />
				{/* <Box display={'flex'} justifyContent={'space-between'}>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
				</Box> */}
				{/* <Box display={'flex'} justifyContent={'space-between'}>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
					<Box>
						<li style={{ fontSize: 13 }}>Round Trip Flights</li>
					</Box>
				</Box> */}

				<p className='price-note' style={{ marginTop: 10 }}>
					{/* This price is lower than the average price in December */}
				</p>
				<div className='price'>
					<div>
						<p className='price-per-person'>
							₹{item?.price_per} <span>/Person</span>
						</p>
					</div>
					{/* <p className='total-price'>Total Price ₹53,318</p> */}
				</div>
			</div>
		</div>
	);
};

export default Card;
