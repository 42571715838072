import { Box, Divider } from '@mui/material';
import React from 'react';
// import './Card.css';

const CardHotel = ({ item }) => {
	return (
		<div className='card'>
			<div className='card-image'>
				<span className='deal-badge'>Deal of the day</span>
				<img
					style={{ height: 250 }}
					src={item.imgpath} // Replace with your image URL
					alt='Beach'
				/>
				{/* <div className='more-options'>1 More Option Available</div> */}
			</div>
			<div className='card-content'>
				<h3>{item.name}</h3>
				<p className='subtitle'>{item.destination}</p>
				{/* <Divider style={{ border: '1px solid lightgrey', marginBottom: 5 }} /> */}
				{/* <p className='price-note' style={{ marginTop: 10 }}>
					This price is lower than the average price in December
				</p> */}
				{/* <div className='price'>
					<div>
						<p className='price-per-person'>
							₹26,659 <span>/Person</span>
						</p>
					</div>
					<p className='total-price'>Total Price ₹53,318</p>
				</div> */}
			</div>
		</div>
	);
};

export default CardHotel;
