import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';

const CardFare = ({ item }) => {
	console.log('item', item);
	const location = useLocation();
	const navigate = useNavigate();
	const { tbo, orderId, fareRule, loading, loadingRule } = useSelector(
		(state) => state.tbo
	);
	const flightOptions = [
		{
			title: 'Deal Of The Day',
			price: '₹7,222',
			details: {
				refundable: 'Non-Refundable',
				changeable: 'Non-Changeable',
				baggage: '15 Kgs',
				handBag: '7 Kgs',
				meal: 'Chargeable',
			},
		},
		{
			title: 'Spice Saver',
			price: '₹9,040',
			details: {
				refundable: 'INR 4,250 within 4 days, INR 3,150 beyond 4 days',
				changeable: 'INR 2,950 within 4 days, INR 2,250 beyond 4 days',
				baggage: '15 Kgs',
				handBag: '7 Kgs',
				meal: 'Chargeable',
			},
		},
		{
			title: 'Spice Flex',
			price: '₹9,566',
			details: {
				refundable: 'INR 4,250 within 4 days, INR 3,150 beyond 4 days',
				changeable: 'INR 1,500 within 4 days, FREE beyond 4 days',
				baggage: '15 Kgs',
				handBag: '7 Kgs',
				meal: 'Free Sandwich',
			},
		},
	];

	const containerStyle = {
		padding: '20px',
		// textAlign: 'center',
		fontFamily: 'Arial, sans-serif',
		backgroundColor: '#f9f9f9',
	};

	const headingStyle = {
		color: '#333',
		marginBottom: '20px',
	};

	const scrollContainerStyle = {
		display: 'flex',
		overflowX: 'auto',
		gap: '15px',
		padding: '10px',
		// height: '350px',
	};

	const cardStyle = {
		position: 'relative',
		backgroundColor: '#fff',
		border: '1px solid #ddd',
		borderRadius: '8px',
		padding: '15px',
		minWidth: '250px',
		boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
		flexShrink: 0,
	};

	const radioStyle = {
		position: 'absolute',
		// top: '10px',
		left: '10px',
	};

	const titleStyle = {
		fontSize: '18px',
		color: '#ff5722',
		// marginBottom: '10px',
		marginLeft: 10,
	};

	const priceStyle = {
		fontSize: '22px',
		color: '#333',
		marginBottom: '15px',
		fontWeight: 'bold',
	};

	const detailsStyle = {
		fontSize: '13px',
		margin: '5px 0',
		color: 'grey',
		marginLeft: 5,
	};

	const buttonStyle = {
		backgroundColor: '#ff5722',
		color: 'white',
		border: 'none',
		padding: '10px 15px',
		fontSize: '14px',
		borderRadius: '5px',
		cursor: 'pointer',
		marginTop: 10,
		width: '100%',
	};

	const buttonHoverStyle = {
		...buttonStyle,
		backgroundColor: '#e64a19',
		marginTop: 10,
	};

	return (
		<div style={containerStyle}>
			{/* <h1 style={headingStyle}>Flight Options</h1> */}
			<div style={scrollContainerStyle}>
				{item?.flights.map((option, index) => (
					<div key={index} style={cardStyle}>
						{/* Radio Button */}
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'>
							<Box display='flex' alignItems={'center'}>
								<input type='radio' name='flight' />

								<h2
									style={{
										fontSize: '18px',
										color: option.FareClassification?.Color,
										// marginBottom: '10px',
										marginLeft: 10,
									}}>
									{option.FareClassification?.Type}
								</h2>
							</Box>
							<h3 style={priceStyle}>
								₹ 
								{option.Supplier === 'TBO'
									? Number(
											Math.round(
												Number(
													Number(Math.round(option.Fare.Total_Amount)) -
														Number(Math.round(option.Fare.MFB_Discount))
												)
											)
									  )
									: ''}
							</h3>
						</Box>
						<div>
							{option?.FareInclusions?.map((i) => {
								return (
									<Box display={'flex'} alignItems={'center'}>
										<CheckCircleOutlineIcon fontSize='12px' />
										<p style={detailsStyle}>{i}</p>
									</Box>
								);
							})}
							{/* <p style={detailsStyle}>
								<strong>Refundable:</strong> {option.details.refundable}
							</p>
							<p style={detailsStyle}>
								<strong>Changeable:</strong> {option.details.changeable}
							</p>
							<p style={detailsStyle}>
								<strong>Baggage:</strong> {option.details.baggage}
							</p>
							<p style={detailsStyle}>
								<strong>Hand Bag:</strong> {option.details.handBag}
							</p>
							<p style={detailsStyle}>
								<strong>Meal:</strong> {option.details.meal}
							</p> */}
						</div>
						<button
							onClick={() =>
								navigate('/flightreview', {
									state: {
										item: option,
										formData: location.state,
										traceId: tbo?.result?.Response.TraceId,
										orderId: orderId.result,
									},
								})
							}
							style={buttonStyle}
							onMouseOver={(e) => (e.target.style.backgroundColor = '#e64a19')}
							onMouseOut={(e) => (e.target.style.backgroundColor = '#ff5722')}>
							Book
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default CardFare;
