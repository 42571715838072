import React, { useEffect } from 'react';
import loc from '../../assets/images/locat.svg';
import calender from '../../assets/images/calender.svg';
import dayjs from 'dayjs';
import flightlogo from '../../assets/images/flightlogo.svg';
import arrivalflight from '../../assets/images/arrialflight.svg';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import flight from '../../assets/images/flight.jpg';
import hotel from '../../assets/images/hotel.jpg';
import holiday from '../../assets/images/holiday.jpg';
import search from '../../assets/images/search.svg';
import Guest from '../../assets/images/Guest.svg';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField, Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	addSearchFlightDispatch,
	cityDispatch,
	showBannerDispatch,
} from '../../reducers/HomeReducer';
import {
	removeSelectedRoom,
	setAdultH,
	setChildAge1,
	setChildAge2,
	setChildAge3,
	setChildAge4,
	setChildH,
	setSelectedArrival,
	setSelectedDepart,
	setSelectedHotel,
	setSelectedRoom,
} from '../../reducers/UiReducer';
import { fetchHotelCityDispatch } from '../../reducers/HotelReducer';
import TravelTabs from './TravelTabs';
const dateFormat = 'YYYY-MM-DD';

const HotelSlider = () => {
	const [trip, setTrip] = useState('one');
	const { city, banner } = useSelector((state) => state.home);
	const { hotelCity } = useSelector((state) => state.hoteltbo);
	const {
		depart,
		arrival,
		hoteldes,
		adultH,
		room,
		childH,
		childAge1,
		childAge2,
		childAge3,
		childAge4,
	} = useSelector((state) => state.ui);
	const date = new Date();
	const [num, setNum] = useState(1);
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const [infant, setInfant] = useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	// const [room, setRoom] = useState(1);
	const [night, setNight] = useState(1);
	const [departDate, setDepartDate] = useState('');
	const [departDate2, setDepartDate2] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	let dateFor = date.toLocaleString().split(',')[0].split('/');
	const newDate = `${dateFor[2]}-${dateFor[0].length > 1 ? '' : 0}${
		dateFor[0]
	}-${dateFor[1]}`;
	console.log('date', newDate);
	useEffect(() => {
		let cityData = {
			keywords: 'b',
			limits: 8,
		};
		dispatch(cityDispatch(cityData));
		dispatch(showBannerDispatch());
	}, []);
	let filter = [];
	if (banner?.result?.length > 0) {
		banner?.result?.map((i) => {
			if (i.inventory === 'Hotel') {
				filter.push({
					...i,
				});
			}
		});
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handlePrev = () => {
		if (num > 1) {
			setNum(num - 1);
		}
	};
	const handleNext = () => {
		if (num > 0 && num < filter.length) {
			setNum(num + 1);
		}
	};
	const handleChange = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchHotelCityDispatch(cityData));
	};
	const handleTrip = (val) => {
		setTrip(val);
	};

	function disabledDate(current) {
		return moment().add(-1, 'days') >= current;
	}
	const onChange = (date, dateString) => {
		setDepartDate(dateString);
	};
	const onChange2 = (date, dateString) => {
		setDepartDate2(dateString);
	};
	console.log('departDate', departDate);
	var date1 = new Date(departDate);
	var date2 = new Date(departDate2);
	var timeDiff = Math.abs(date2.getTime() - date1.getTime());
	var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
	console.log('numberofnights', numberOfNights);
	const handleTBO = () => {
		let formData = {
			checkInDate: departDate,
			checkOutDate: departDate2,
			noOfNights: numberOfNights,
			countryName: hoteldes.country_name,
			countryCode: hoteldes.country_code,
			cityName: hoteldes.city_name,
			cityId: hoteldes.city_id,
			noOfRooms: room.length,
			guestNationality: 'IN',
			adultPax: adultH,
			childPax: childH,
			childAge: [...childAge1, ...childAge2, ...childAge3, ...childAge4],
			preferredCurrencyCode: 'INR',
			hotelCodeList: '0',
			starMaxRating: 5,
			startMinRating: 0,
		};
		// $1$pLx7Cf0C$JH0bormSXf/acY34KhBce1
		navigate('/hotelresult', { state: formData });
	};

	return (
		<>
			<Box style={{ paddingLeft: 80, paddingRight: 80, paddingTop: 50 }}>
				<TravelTabs />
				<div
					style={{
						backgroundColor: 'rgba(255, 255, 255, 0.12)',
						borderRadius: 5,
						padding: 20,
						paddingTop: 60,
					}}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<Box display={'flex'}>
								<img
									src={loc}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
									}}
								/>
								<Box style={{ width: '100%' }}>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 14,
										}}>
										Location
									</span>
									<Autocomplete
										required
										id='country-select-demo'
										sx={{
											width: '100%',
											marginLeft: 2,
											marginTop: 1,
											'& .MuiFormLabel-root': {
												color: 'white',
											},
											'& .MuiInputBase-input': {
												color: 'rgba(207, 201, 201, 1)',
											},
											'& .MuiSvgIcon-root': {
												color: 'white',
											},
										}}
										options={
											hotelCity && hotelCity.result.length > 0
												? hotelCity.result
												: []
										}
										autoHighlight
										ListboxProps={{
											className: 'myCustomList',
										}}
										value={hoteldes}
										getOptionLabel={(option) =>
											`${option.city_name} (${option.country_name})`
										}
										renderOption={(props, option) => (
											<Box
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}
												component='li'
												sx={{
													'& > img': {
														mr: 2,
														flexShrink: 0,
														fontSize: 14,
													},
												}}
												{...props}>
												<Box>
													<Box display={'flex'}>
														<img
															src={flightlogo}
															style={{ width: 20, height: 20 }}
														/>
														<p style={{ fontSize: 13, marginLeft: 5 }}>
															{option.city_name} - {option.country_code}
														</p>
													</Box>
													<p
														style={{
															fontSize: 13,
															marginLeft: 25,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '200px',
															whiteSpace: 'nowrap',
														}}>
														{option.city_name}
													</p>
												</Box>
												<img
													loading='lazy'
													width='25'
													height='25'
													src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
													srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
													alt=''
												/>
											</Box>
										)}
										onChange={(event, value) => {
											dispatch(setSelectedHotel(value));
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												id='standard-basic'
												// label='To'
												variant='standard'
												onChange={handleChange}
												style={{ fontSize: 12 }}
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // disable autocomplete and autofill
												}}
											/>
										)}
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2.5}>
							<Box display={'flex'} style={{ width: '100%' }}>
								<img
									src={calender}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
										marginTop: 5,
									}}
								/>
								<Box>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 14,
										}}>
										Check In
									</span>
									<DatePicker
										disabledDate={disabledDate}
										onChange={onChange}
										placeholder=''
										// format='DD-MMYYYY'
										// defaultValue={moment(date.toISOString().split('T')[0], dateFormat)}
										// format={dateFormat}
										style={{
											width: '100%',
											padding: 8,
											border: 'none',
											borderBottom: '1px solid',
											borderRadius: '1px',
											boxShadow: 'none',
											background: 'transparent',
										}}
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2.5}>
							<Box display={'flex'} style={{ width: '100%' }}>
								<img
									src={calender}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
										marginTop: 5,
									}}
								/>
								<Box>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 14,
										}}>
										Check Out
									</span>
									<DatePicker
										disabledDate={disabledDate}
										onChange={onChange2}
										placeholder=''
										// format='DD-MMYYYY'
										// defaultValue={moment(date.toISOString().split('T')[0], dateFormat)}
										// format={dateFormat}
										style={{
											width: '100%',
											padding: 8,
											border: 'none',
											borderBottom: '1px solid',
											borderRadius: '1px',
											boxShadow: 'none',
											background: 'transparent',
										}}
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box display={'flex'}>
								<img
									src={Guest}
									style={{
										height: 25,
										width: 25,
										objectFit: 'contain',
										marginTop: 5,
									}}
								/>
								<Box>
									<span
										style={{
											color: 'white',
											marginLeft: 10,
											fontSize: 14,
										}}>
										Guest
									</span>
									<Button
										style={{
											backgroud: 'transparent',
											color: 'white',
											textTransform: 'capitalize',
										}}
										aria-describedby={id}
										variant='text'
										onClick={handleClick}>
										{adult} Adult {child} Child
									</Button>
									<Popover
										id={id}
										open={open}
										style={{ marginTop: 5, padding: 10 }}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}>
										<ul style={{ padding: 10 }}>
											{room.map((i, index) => {
												return (
													<li>
														<Box style={{ padding: 5 }}>
															<span
																style={{
																	color: 'black',
																	fontSize: 14,
																	fontWeight: 500,
																	fontFamily: 'Poppins',
																}}>
																Room {index + 1}
															</span>
															<Box
																display='flex'
																justifyContent='space-between'>
																<Box>
																	<span
																		style={{
																			color: 'black',
																			fontSize: 14,
																			fontWeight: 500,
																			fontFamily: 'Poppins',
																		}}>
																		Adult
																	</span>
																	<span
																		style={{
																			fontSize: 10,
																			fontWeight: 'grey',
																			marginLeft: 5,
																		}}>
																		Above 12 years
																	</span>
																	<Box display='flex' alignItems='center'>
																		<Box
																			style={{
																				cursor: 'pointer',
																				background: 'rgb(8 128 130)',
																				padding: '0px 10px',
																				// borderRadius: '5px',
																			}}
																			onClick={() => {
																				dispatch(
																					setAdultH(
																						`${adultH[index] - 1}A${index}`
																					)
																				);
																			}}>
																			<span
																				style={{
																					fontSize: 16,
																					color: 'white',
																				}}>
																				-
																			</span>
																		</Box>
																		<Box
																			style={{
																				cursor: 'pointer',
																				background: 'lightgrey',
																				padding: '0px 10px',
																			}}>
																			<span
																				style={{
																					fontSize: 16,
																					color: 'black',
																				}}>
																				{adultH[index] ? adultH[index] : 0}
																			</span>
																		</Box>
																		<Box
																			style={{
																				cursor: 'pointer',
																				background: 'rgb(8 128 130)',
																				padding: '0px 10px',
																			}}
																			onClick={() => {
																				dispatch(
																					setAdultH(
																						`${
																							adultH[index]
																								? adultH[index] + 1
																								: 0 + 1
																						}A${index}`
																					)
																				);
																			}}>
																			<span
																				style={{
																					fontSize: 16,
																					color: 'white',
																				}}>
																				+
																			</span>
																		</Box>
																	</Box>
																</Box>
																<Box ml={4}>
																	<span
																		style={{
																			color: 'black',
																			fontSize: 14,
																			fontWeight: 500,
																			fontFamily: 'Poppins',
																		}}>
																		Child
																	</span>
																	<span
																		style={{
																			fontSize: 10,
																			fontWeight: 'grey',
																			marginLeft: 5,
																		}}>
																		Below 12 years
																	</span>
																	<Box display='flex' alignItems='center'>
																		<Box
																			style={{
																				cursor: 'pointer',
																				background: 'rgb(8 128 130)',
																				padding: '0px 10px',
																			}}
																			onClick={() => {
																				dispatch(
																					setChildH(
																						`${childH[index] - 1}C${index}`
																					)
																				);
																			}}>
																			<span
																				style={{
																					fontSize: 16,
																					color: 'white',
																				}}>
																				-
																			</span>
																		</Box>
																		<Box
																			style={{
																				cursor: 'pointer',
																				background: 'lightgrey',
																				padding: '0px 10px',
																			}}>
																			<span
																				style={{
																					fontSize: 16,
																					color: 'black',
																				}}>
																				{childH[index] ? childH[index] : 0}
																			</span>
																		</Box>
																		<Box
																			style={{
																				cursor: 'pointer',
																				background: 'rgb(8 128 130)',
																				padding: '0px 10px',
																			}}
																			onClick={() => {
																				dispatch(
																					setChildH(
																						`${
																							childH[index]
																								? childH[index] + 1
																								: 0 + 1
																						}C${index}`
																					)
																				);
																			}}>
																			<span
																				style={{
																					fontSize: 16,
																					color: 'white',
																				}}>
																				+
																			</span>
																		</Box>
																	</Box>
																</Box>
															</Box>
														</Box>
														<Box
															display='flex'
															style={{
																paddingRight: '27px',
																marginTop: 10,
															}}>
															{Array.from(Array(childH[index])).map(
																(i, ind) => {
																	return (
																		<Box ml={ind === 0 ? '' : '5px'}>
																			<label for='cars'>Age:</label>
																			<select
																				onChange={(e) =>
																					dispatch(
																						index === 0
																							? setChildAge1(
																									`${e.target.value}C${ind}`
																							  )
																							: index === 1
																							? setChildAge2(
																									`${e.target.value}C${ind}`
																							  )
																							: index === 2
																							? setChildAge3(
																									`${e.target.value}C${ind}`
																							  )
																							: index === 3
																							? setChildAge4(
																									`${e.target.value}C${ind}`
																							  )
																							: ''
																					)
																				}
																				name='cars'
																				id='cars'
																				style={{
																					border: '1px solid grey',
																					paddingLeft: 10,
																					paddingRight: 10,
																					marginLeft: 5,
																				}}>
																				<option value='1'>1</option>
																				<option value='2'>2</option>
																				<option value='3'>3</option>
																				<option value='4'>4</option>
																				<option value='5'>5</option>
																				<option value='6'>6</option>
																				<option value='7'>7</option>
																				<option value='8'>8</option>
																				<option value='9'>9</option>
																				<option value='10'>10</option>
																				<option value='11'>11</option>
																				<option value='12'>12</option>
																			</select>
																		</Box>
																	);
																}
															)}
														</Box>
													</li>
												);
											})}
											<li>
												<Box
													display='flex'
													justifyContent='space-between'
													style={{ marginTop: 20 }}>
													<Box
														onClick={() => dispatch(setSelectedRoom(1))}
														style={{
															border: '1px solid grey',
															paddingLeft: '5px',
															paddingRight: '5px',
															cursor: 'pointer',
															borderRadius: 5,
														}}>
														<span style={{ fontSize: 12 }}>Add Room</span>
													</Box>
													<Box
														onClick={() => dispatch(removeSelectedRoom(1))}
														style={{
															border: '1px solid #ea2330',
															paddingLeft: '5px',
															paddingRight: '5px',
															cursor: 'pointer',
															background: '#ea2330',
															color: 'white',
															borderRadius: 5,
														}}>
														<span style={{ fontSize: 12 }}>Remove Room</span>
													</Box>
													{/* <Box
																style={{
																	border: '1px solid grey',
																	paddingLeft: '5px',
																	paddingRight: '5px',
																	cursor: 'pointer',
																	background: 'rgb(0, 55, 114)',
																	color: 'white',
																}}>
																<span style={{ fontSize: 12 }}>Done</span>
															</Box> */}
												</Box>
											</li>
										</ul>
										{/* <Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														ADULTS (12y +)<br></br>
														<span style={{ fontSize: 10 }}>
															on the day of travel
														</span>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setAdult(adult - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{adult}
														</Box>
														<Box
															onClick={() => setAdult(adult + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box>
												<Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														CHILDS (2y-12y)<br></br>
														<span style={{ fontSize: 10 }}>
															on the day of travel
														</span>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setChild(child - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{child}
														</Box>
														<Box
															onClick={() => setChild(child + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box>

												<Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														No. of Nights<br></br>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setNight(night - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{night}
														</Box>
														<Box
															onClick={() => setNight(night + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box>
												<Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														No. of Rooms<br></br>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setRoom(room - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{room}
														</Box>
														<Box
															onClick={() => setRoom(room + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box> */}
									</Popover>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={1}>
							<Box
								onClick={handleTBO}
								// onClick={() => navigate('/hotelresult')}
								style={{
									backgroundColor: 'rgb(253 121 14) ',
									textAlign: 'center',
									padding: matches ? '20px' : '30px',
									borderRadius: '5px',
									cursor: 'pointer',
								}}
								textAlign={'center'}>
								<img
									src={search}
									style={{
										height: 25,
										width: 25,
										display: 'inline',
										objectFit: 'contain',
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				</div>
			</Box>
		</>
	);
};

export default HotelSlider;
