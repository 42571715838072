import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import ExploreWorld from '../components/resuableComponents/ExploreWorld';
import Activities from '../components/resuableComponents/Activities';
import TopDest from '../components/resuableComponents/TopDest';
import BottomBanner from '../components/resuableComponents/BottomBanner';
import Package from '../components/resuableComponents/Package';
import HotelSlider from '../components/resuableComponents/HotelSlider';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedHeaderTab } from '../reducers/UiReducer';
import { Box } from '@mui/material';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import Hotel from './Hotel';

const HotelHomePage = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { banner, tourdest } = useSelector((state) => state.home);
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.pathname === '/hotel') {
			dispatch(setSelectedHeaderTab('hotel'));
		}
	}, []);
	return (
		<>
			<div
				style={{
					backgroundImage: `url(${banner?.result[0]?.imgpath})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					height: '600px',
					width: '100%',
				}}>
				<Navbar />
				<MNavbar />
				<HotelSlider />
				<Box style={{ marginTop: 400 }}>
					<ExploreWorld />
				</Box>
				<Package />
				<Hotel />
				{/* <Activities /> */}
				{/* <Adventure /> */}
				{/* <TopDest />
			<BottomBanner /> */}
				<Footer />
			</div>
		</>
	);
};

export default HotelHomePage;
