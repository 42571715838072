import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import {
	Box,
	Container,
	Grid,
	Button,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import Steppers from '../components/resuableComponents/Steppers';
import { useLocation, useNavigate } from 'react-router-dom';
import Line from '../assets/images/Line.svg';
import { Divider } from 'antd';
import FlightSummaryForm from '../forms/FlightSummaryForm';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import {
	TBOFareQuoteDispatch,
	TBOFareQuoteReturnDispatch,
} from '../reducers/TBOReducer';
import { useDispatch, useSelector } from 'react-redux';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import { walletBalanceAgentDispatch } from '../reducers/AgentReducer';

const ReviewFlight = () => {
	const location = useLocation();
	console.log('Location', location);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { loading, loadingReturn } = useSelector((state) => state.tbo);
	const { depart, arrival } = useSelector((state) => state.ui);
	const { agentInfo } = useSelector((state) => state.agent);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	const timedifference = (t1, t2) => {
		const date1 = new Date(t1);
		const date2 = new Date(t2);
		const diff = date2.getTime() - date1.getTime();
		return Math.abs(Math.floor(diff / 1000 / 60));
	};
	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// 	dispatch(
	// 		walletBalanceAgentDispatch(
	// 			agentInfo?.result?.result?.email,
	// 			agentInfo?.result?.result?.id
	// 		)
	// 	);
	// 	if (location.state.formData.JourneyType === 1) {
	// 		let formData = {
	// 			TraceId: location.state.traceId,
	// 			ResultIndex: location.state.item?.ResultIndex,
	// 			agent_id: agentInfo?.status === 200 ? agentInfo?.result?.result?.id : 0,
	// 		};
	// 		// dispatch(TBOFareQuoteDispatch(formData, navigate));
	// 	} else {
	// 		if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
	// 			let formData = {
	// 				TraceId: location.state.traceId,
	// 				ResultIndex: location.state.onwardFlight?.ResultIndex,
	// 				agent_id:
	// 					agentInfo?.status === 200 ? agentInfo?.result?.result?.id : 0,
	// 			};
	// 			dispatch(TBOFareQuoteDispatch(formData, navigate));
	// 			let formDataReturn = {
	// 				TraceId: location.state.traceId,
	// 				ResultIndex: location.state.returnFlight?.ResultIndex,
	// 				agent_id:
	// 					agentInfo?.status === 200 ? agentInfo?.result?.result?.id : 0,
	// 			};
	// 			dispatch(TBOFareQuoteReturnDispatch(formDataReturn, navigate));
	// 		} else {
	// 			let formData = {
	// 				TraceId: location.state.traceId,
	// 				ResultIndex: location.state.item?.ResultIndex,
	// 				agent_id:
	// 					agentInfo?.status === 200 ? agentInfo?.result?.result?.id : 0,
	// 			};
	// 			dispatch(TBOFareQuoteDispatch(formData, navigate));
	// 		}
	// 	}
	// }, []);
	return (
		<div>
			<Navbar />
			<MNavbar />
			<Box
				style={{
					background: 'rgb(253 121 14) ',
					padding: matches ? 15 : 25,
				}}>
				<Container>
					<Steppers step={1} />
				</Container>
			</Box>
			<Container style={{ marginTop: 30 }}>
				<h1 style={{ fontSize: matches ? 20 : 26, fontWeight: 'bold' }}>
					Review your Booking
				</h1>
				<Grid container spacing={4} style={{ marginTop: 10 }}>
					<Grid item xs={12} lg={8}>
						<Box
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								padding: 20,
								background: 'white',
								borderRadius: 5,
							}}>
							{location.state.formData.JourneyType === 1 && (
								<>
									<h1 style={{ fontWeight: 'bold' }}>
										{location.state.item.Supplier === 'TBO'
											? location.state.item.Segments[0][0].Origin.Airport
													.CityName
											: ''}{' '}
										-{' '}
										{location.state.item.Supplier === 'TBO'
											? location.state.item.Segments[0].length == 2
												? location.state.item.Segments[0]
														.filter((i, index) => index === 1)
														.map((it) => {
															return it.Destination.Airport.CityName;
														})
												: location.state.item.Segments[0].length == 3
												? location.state.item.Segments[0]
														.filter((i, index) => index === 2)
														.map((it) => {
															return it.Destination.Airport.CityName;
														})
												: location.state.item.Segments[0].length == 1
												? location.state.item.Segments[0].map((it) => {
														return it.Destination.Airport.CityName;
												  })
												: ''
											: ''}
									</h1>
									<p style={{ color: 'rgba(143, 143, 143, 1)', fontSize: 14 }}>
										All departure/arrival times are in local time
									</p>
									<Box
										mt={2}
										style={{
											border: '1.33px solid rgba(203, 203, 203, 1)',
											padding: 15,
											borderRadius: 5,
										}}>
										{location.state.item.Segments[0].map((i) => {
											return (
												<>
													<Box
														mt={2}
														display={'flex'}
														alignItems={'center'}
														justifyContent={'space-between'}>
														<Box display={'flex'} alignItems={'center'}>
															<img
																src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																style={{ borderRadius: 5, height: 45 }}
															/>
															<Box ml={2}>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Airline.AirlineName}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}</p>
															</Box>
														</Box>
														<Box
															style={{
																backgroundColor: i.FareClassification.Color,
																border: '1px solid grey',
																padding: 5,
																borderRadius: 5,
															}}>
															{i.FareClassification.Type}
														</Box>
													</Box>
													<Box
														display={'flex'}
														justifyContent={'space-between'}
														mt={2}>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																{i.Origin.DepTime.substr(11, 5)}
															</p>
															<p style={{ fontFamily: 'Outfit' }}>
																{i.Origin.Airport.AirportCode}
															</p>
															<p style={{ fontSize: 12 }}>
																{i.Origin.DepTime.split('T')[0]}
															</p>
															<p style={{ fontSize: 12 }}>
																{i.Origin.Airport.CityName}
															</p>

															<p style={{ fontSize: 12 }}>
																{`Terminal ${i.Origin.Airport.Terminal}`}
															</p>
															<p
																title={`${i.Origin.Airport.AirportName},${i.Origin.Airport.CountryName}`}
																style={{
																	fontWeight: 'bold',
																	textOverflow: 'ellipsis',
																	overflow: 'hidden',
																	width: '95px',
																	height: '1.2em',
																	whiteSpace: 'nowrap',
																	fontSize: 12,
																}}>
																{i.Origin.Airport.AirportName},{' '}
																{i.Origin.Airport.CountryName}
															</p>
														</Box>
														<Box textAlign={'center'}>
															<p style={{ fontSize: 14 }}>
																{timeConvert(
																	timedifference(
																		i.Origin.DepTime,
																		i.Destination.ArrTime
																	)
																)}
															</p>
															<img src={Line} />
															{/* <p style={{  fontSize: 14 }}>
											Non-Stop
										</p> */}
														</Box>
														<Box textAlign={'end'}>
															<p
																style={{
																	fontWeight: 'bold',
																}}>
																{i.Destination.ArrTime.substr(11, 5)}
															</p>
															<p style={{ fontFamily: 'Outfit' }}>
																{i.Destination.Airport.AirportCode}
															</p>
															<p style={{ fontSize: 12 }}>
																{i.Destination.ArrTime.split('T')[0]}
															</p>
															<p style={{ fontSize: 12 }}>
																{i.Destination.Airport.CityName}
															</p>
															<p style={{ fontSize: 12 }}>
																{`Terminal ${i.Destination.Airport.Terminal}`}
															</p>
															<p
																title={`${i.Destination.Airport.AirportName},${i.Destination.Airport.CountryName}`}
																style={{
																	fontWeight: 'bold',
																	textOverflow: 'ellipsis',
																	overflow: 'hidden',
																	width: '95px',
																	height: '1.2em',
																	whiteSpace: 'nowrap',
																	fontSize: 12,
																}}>
																{i.Destination.Airport.AirportName},{' '}
																{i.Destination.Airport.CountryName}
															</p>
														</Box>
													</Box>
													<Divider
														style={{
															border: '1.33px solid rgba(203, 203, 203, 1)',
														}}
													/>
												</>
											);
										})}
									</Box>
								</>
							)}
							{location.state.formData.JourneyType === 2 ? (
								depart.country_code === 'IN' &&
								arrival.country_code === 'IN' ? (
									<>
										<h1 style={{ fontWeight: 'bold' }}>
											{location?.state.onwardFlight?.Supplier === 'TBO'
												? location.state.onwardFlight.Segments[0][0].Origin
														.Airport.CityName
												: ''}{' '}
											-{' '}
											{location.state.onwardFlight.Supplier === 'TBO'
												? location.state.onwardFlight.Segments[0].length == 2
													? location.state.onwardFlight.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.onwardFlight.Segments[0].length == 3
													? location.state.onwardFlight.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.onwardFlight.Segments[0].length == 1
													? location.state.onwardFlight.Segments[0].map(
															(it) => {
																return it.Destination.Airport.CityName;
															}
													  )
													: ''
												: ''}
										</h1>
										<p
											style={{ color: 'rgba(143, 143, 143, 1)', fontSize: 14 }}>
											All departure/arrival times are in local time
										</p>
										<Box
											mt={2}
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 15,
												borderRadius: 5,
											}}>
											{location.state.onwardFlight.Segments[0].map((i) => {
												return (
													<>
														<Box
															mt={2}
															display={'flex'}
															alignItems={'center'}
															justifyContent={'space-between'}>
															<Box display={'flex'} alignItems={'center'}>
																<img
																	src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																	style={{ borderRadius: 5, height: 45 }}
																/>
																<Box ml={2}>
																	<p
																		style={{
																			fontWeight: 'bold',
																		}}>
																		{i.Airline.AirlineName}
																	</p>
																	<p
																		style={{
																			fontSize: 12,
																		}}>{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}</p>
																</Box>
															</Box>
															<Box
																style={{
																	backgroundColor: i.FareClassification.Color,
																	border: '1px solid grey',
																	padding: 5,
																	borderRadius: 5,
																}}>
																{i.FareClassification.Type}
															</Box>
														</Box>
														<Box
															display={'flex'}
															justifyContent={'space-between'}
															mt={2}>
															<Box>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Origin.DepTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Origin.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.DepTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.CityName}
																</p>

																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Origin.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Origin.Airport.AirportName},${i.Origin.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.AirportName},{' '}
																	{i.Origin.Airport.CountryName}
																</p>
															</Box>
															<Box textAlign={'center'}>
																<p
																	style={{
																		fontSize: 14,
																	}}>
																	{timeConvert(
																		timedifference(
																			i.Origin.DepTime,
																			i.Destination.ArrTime
																		)
																	)}
																</p>
																<img src={Line} />
																{/* <p style={{  fontSize: 14 }}>
											Non-Stop
										</p> */}
															</Box>
															<Box textAlign={'end'}>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Destination.ArrTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Destination.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.ArrTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.CityName}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Destination.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Destination.Airport.AirportName},${i.Destination.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.AirportName},{' '}
																	{i.Destination.Airport.CountryName}
																</p>
															</Box>
														</Box>
														<Divider
															style={{
																border: '1.33px solid rgba(203, 203, 203, 1)',
															}}
														/>
													</>
												);
											})}
										</Box>
										<br />
										<h1 style={{ fontWeight: 'bold' }}>
											{location?.state.returnFlight?.Supplier === 'TBO'
												? location.state.returnFlight.Segments[0][0].Origin
														.Airport.CityName
												: ''}{' '}
											-{' '}
											{location.state.returnFlight.Supplier === 'TBO'
												? location.state.returnFlight.Segments[0].length == 2
													? location.state.returnFlight.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.returnFlight.Segments[0].length == 3
													? location.state.returnFlight.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.returnFlight.Segments[0].length == 1
													? location.state.returnFlight.Segments[0].map(
															(it) => {
																return it.Destination.Airport.CityName;
															}
													  )
													: ''
												: ''}
										</h1>
										<p
											style={{ color: 'rgba(143, 143, 143, 1)', fontSize: 14 }}>
											All departure/arrival times are in local time
										</p>
										<Box
											mt={2}
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 15,
												borderRadius: 5,
											}}>
											{location.state.returnFlight.Segments[0].map((i) => {
												return (
													<>
														<Box
															mt={2}
															display={'flex'}
															alignItems={'center'}
															justifyContent={'space-between'}>
															<Box display={'flex'} alignItems={'center'}>
																<img
																	src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																	style={{ borderRadius: 5, height: 45 }}
																/>
																<Box ml={2}>
																	<p
																		style={{
																			fontWeight: 'bold',
																		}}>
																		{i.Airline.AirlineName}
																	</p>
																	<p
																		style={{
																			fontSize: 12,
																		}}>{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}</p>
																</Box>
															</Box>
															<Box
																style={{
																	backgroundColor: i.FareClassification.Color,
																	border: '1px solid grey',
																	padding: 5,
																	borderRadius: 5,
																}}>
																{i.FareClassification.Type}
															</Box>
														</Box>
														<Box
															display={'flex'}
															justifyContent={'space-between'}
															mt={2}>
															<Box>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Origin.DepTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Origin.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.DepTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.CityName}
																</p>

																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Origin.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Origin.Airport.AirportName},${i.Origin.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.AirportName},{' '}
																	{i.Origin.Airport.CountryName}
																</p>
															</Box>
															<Box textAlign={'center'}>
																<p
																	style={{
																		fontSize: 14,
																	}}>
																	{timeConvert(
																		timedifference(
																			i.Origin.DepTime,
																			i.Destination.ArrTime
																		)
																	)}
																</p>
																<img src={Line} />
																{/* <p style={{  fontSize: 14 }}>
											Non-Stop
										</p> */}
															</Box>
															<Box textAlign={'end'}>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Destination.ArrTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Destination.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.ArrTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.CityName}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Destination.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Destination.Airport.AirportName},${i.Destination.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.AirportName},{' '}
																	{i.Destination.Airport.CountryName}
																</p>
															</Box>
														</Box>
														<Divider
															style={{
																border: '1.33px solid rgba(203, 203, 203, 1)',
															}}
														/>
													</>
												);
											})}
										</Box>
									</>
								) : (
									<>
										<h1 style={{ fontWeight: 'bold' }}>
											{location?.state.item?.Supplier === 'TBO'
												? location.state.item.Segments[0][0].Origin.Airport
														.CityName
												: ''}{' '}
											-{' '}
											{location.state.item.Supplier === 'TBO'
												? location.state.item.Segments[0].length == 2
													? location.state.item.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.item.Segments[0].length == 3
													? location.state.item.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.item.Segments[0].length == 1
													? location.state.item.Segments[0].map((it) => {
															return it.Destination.Airport.CityName;
													  })
													: ''
												: ''}
										</h1>
										<p
											style={{ color: 'rgba(143, 143, 143, 1)', fontSize: 14 }}>
											All departure/arrival times are in local time
										</p>
										<Box
											mt={2}
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 15,
												borderRadius: 5,
											}}>
											{location.state.item.Segments[0].map((i) => {
												return (
													<>
														<Box
															mt={2}
															display={'flex'}
															alignItems={'center'}
															justifyContent={'space-between'}>
															<Box display={'flex'} alignItems={'center'}>
																<img
																	src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																	style={{ borderRadius: 5, height: 45 }}
																/>
																<Box ml={2}>
																	<p
																		style={{
																			fontWeight: 'bold',
																		}}>
																		{i.Airline.AirlineName}
																	</p>
																	<p
																		style={{
																			fontSize: 12,
																		}}>{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}</p>
																</Box>
															</Box>
															<Box
																style={{
																	backgroundColor: i.FareClassification.Color,
																	border: '1px solid grey',
																	padding: 5,
																	borderRadius: 5,
																}}>
																{i.FareClassification.Type}
															</Box>
														</Box>
														<Box
															display={'flex'}
															justifyContent={'space-between'}
															mt={2}>
															<Box>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Origin.DepTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Origin.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.DepTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.CityName}
																</p>

																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Origin.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Origin.Airport.AirportName},${i.Origin.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.AirportName},{' '}
																	{i.Origin.Airport.CountryName}
																</p>
															</Box>
															<Box textAlign={'center'}>
																<p
																	style={{
																		fontSize: 14,
																	}}>
																	{timeConvert(
																		timedifference(
																			i.Origin.DepTime,
																			i.Destination.ArrTime
																		)
																	)}
																</p>
																<img src={Line} />
																{/* <p style={{  fontSize: 14 }}>
											Non-Stop
										</p> */}
															</Box>
															<Box textAlign={'end'}>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Destination.ArrTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Destination.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.ArrTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.CityName}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Destination.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Destination.Airport.AirportName},${i.Destination.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.AirportName},{' '}
																	{i.Destination.Airport.CountryName}
																</p>
															</Box>
														</Box>
														<Divider
															style={{
																border: '1.33px solid rgba(203, 203, 203, 1)',
															}}
														/>
													</>
												);
											})}
										</Box>
										<h1 style={{ fontWeight: 'bold', marginTop: 10 }}>
											{location.state.item.Supplier === 'TBO'
												? location.state.item.Segments[1][0].Origin.Airport
														.CityName
												: ''}{' '}
											-{' '}
											{location.state.item.Supplier === 'TBO'
												? location.state.item.Segments[1].length == 2
													? location.state.item.Segments[1]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.item.Segments[1].length == 3
													? location.state.item.Segments[1]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.Airport.CityName;
															})
													: location.state.item.Segments[1].length == 1
													? location.state.item.Segments[1].map((it) => {
															return it.Destination.Airport.CityName;
													  })
													: ''
												: ''}
										</h1>
										<p
											style={{
												color: 'rgba(143, 143, 143, 1)',
												fontSize: 14,
											}}>
											All departure/arrival times are in local time
										</p>
										<Box
											mt={2}
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 15,
												borderRadius: 5,
											}}>
											{location.state.item.Segments[1].map((i) => {
												return (
													<>
														<Box
															mt={2}
															display={'flex'}
															alignItems={'center'}
															justifyContent={'space-between'}>
															<Box display={'flex'} alignItems={'center'}>
																<img
																	src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
																	style={{ borderRadius: 5, height: 45 }}
																/>
																<Box ml={2}>
																	<p
																		style={{
																			fontWeight: 'bold',
																		}}>
																		{i.Airline.AirlineName}
																	</p>
																	<p
																		style={{
																			fontSize: 12,
																		}}>{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}</p>
																</Box>
															</Box>
															<Box
																style={{
																	backgroundColor: i.FareClassification.Color,
																	border: '1px solid grey',
																	padding: 5,
																	borderRadius: 5,
																}}>
																{i.FareClassification.Type}
															</Box>
														</Box>
														<Box
															display={'flex'}
															justifyContent={'space-between'}
															mt={2}>
															<Box>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Origin.DepTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Origin.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.DepTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.CityName}
																</p>

																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Origin.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Origin.Airport.AirportName},${i.Origin.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Origin.Airport.AirportName},{' '}
																	{i.Origin.Airport.CountryName}
																</p>
															</Box>
															<Box textAlign={'center'}>
																<p
																	style={{
																		fontSize: 14,
																	}}>
																	{timeConvert(
																		timedifference(
																			i.Origin.DepTime,
																			i.Destination.ArrTime
																		)
																	)}
																</p>
																<img src={Line} />
																{/* <p style={{  fontSize: 14 }}>
											Non-Stop
										</p> */}
															</Box>
															<Box textAlign={'end'}>
																<p
																	style={{
																		fontWeight: 'bold',
																	}}>
																	{i.Destination.ArrTime.substr(11, 5)}
																</p>
																<p style={{ fontFamily: 'Outfit' }}>
																	{i.Destination.Airport.AirportCode}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.ArrTime.split('T')[0]}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.CityName}
																</p>
																<p
																	style={{
																		fontSize: 12,
																	}}>
																	{`Terminal ${i.Destination.Airport.Terminal}`}
																</p>
																<p
																	title={`${i.Destination.Airport.AirportName},${i.Destination.Airport.CountryName}`}
																	style={{
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '95px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																		fontSize: 12,
																	}}>
																	{i.Destination.Airport.AirportName},{' '}
																	{i.Destination.Airport.CountryName}
																</p>
															</Box>
														</Box>
														<Divider
															style={{
																border: '1.33px solid rgba(203, 203, 203, 1)',
															}}
														/>
													</>
												);
											})}
										</Box>
									</>
								)
							) : (
								''
							)}
						</Box>
						{matches ? '' : <FlightSummaryForm />}
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								padding: 10,
								background: 'white',
								borderRadius: 5,
							}}>
							<span
								style={{
									fontSize: 18,
									fontWeight: '500',
									color: 'rgba(29, 29, 29, 1)',
								}}>
								Fare Summary
							</span>
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
										lineHeight: '10px',
									}}>
									Base Fare
								</span>
								<span
									style={{
										fontSize: 16,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹
									{location.state.formData.JourneyType === 1
										? Number(
												Math.round(
													Number(location.state.item.Fare.Total_Amount) -
														Number(location.state.item.Fare.MFB_Discount)
												)
										  )
										: location.state.formData.JourneyType === 2
										? depart.country_code === 'IN' &&
										  arrival.country_code === 'IN'
											? Number(
													Math.round(
														Number(
															Number(
																location.state.onwardFlight.Fare.Total_Amount
															) -
																Number(
																	location.state.onwardFlight.Fare.MFB_Discount
																)
														) +
															Number(
																Number(
																	location.state.returnFlight.Fare.Total_Amount
																) -
																	Number(
																		location.state.returnFlight.Fare
																			.MFB_Discount
																	)
															)
													)
											  )
											: Number(
													Math.round(
														Number(location.state.item.Fare.Total_Amount) -
															Number(location.state.item.Fare.MFB_Discount)
													)
											  )
										: ''}
								</span>
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									{location.state.formData.AdultCount > 0
										? `Adult(${location.state.formData.AdultCount})`
										: ''}
								</p>
								{/* <p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ 4000
								</p> */}
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									{location.state.formData.ChildCount > 0
										? `Child(${location.state.formData.ChildCount})`
										: ''}
								</p>
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									{location.state.formData.InfantCount > 0
										? `Infant(${location.state.formData.InfantCount})`
										: ''}
								</p>
							</Box>{' '}
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							{/* <Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Taxes
								</span>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹{' '}
									{location.state.formData.JourneyType === 1
										? location.state.item.Fare.AirportTax_Amount
										: location.state.formData.JourneyType === 2
										? depart.country_code === 'IN' &&
										  arrival.country_code === 'IN'
											? Number(
													Math.round(
														Number(
															Number(
																location.state.onwardFlight.Fare
																	.AirportTax_Amount
															)
														) +
															Number(
																Number(
																	location.state.returnFlight.Fare
																		.AirportTax_Amount
																)
															)
													)
											  )
											: location.state.item.Fare.AirportTax_Amount
										: ''}
								</span>
							</Box> */}
							{/* <Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Taxes & Fees
								</p>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ 4000
								</p>
							</Box>{' '} */}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Convience
								</p>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ 0
								</p>
							</Box>{' '}
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Grand Total
								</span>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹{' '}
									{location.state.formData.JourneyType === 1
										? Number(
												Math.round(
													Number(location.state.item.Fare.Total_Amount) -
														Number(location.state.item.Fare.MFB_Discount)
												)
										  )
										: location.state.formData.JourneyType === 2
										? depart.country_code === 'IN' &&
										  arrival.country_code === 'IN'
											? Number(
													Math.round(
														Number(
															Number(
																location.state.onwardFlight.Fare.Total_Amount
															) -
																Number(
																	location.state.onwardFlight.Fare.MFB_Discount
																)
														) +
															Number(
																Number(
																	location.state.returnFlight.Fare.Total_Amount
																) -
																	Number(
																		location.state.returnFlight.Fare
																			.MFB_Discount
																	)
															)
													)
											  )
											: Number(
													Math.round(
														Number(location.state.item.Fare.Total_Amount) -
															Number(location.state.item.Fare.MFB_Discount)
													)
											  )
										: ''}
								</span>
							</Box>
						</Box>
						{/* <Box
							mt={4}
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								padding: 20,
							}}>
							<h1>Promo Code</h1>
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<h2>Have a discount/Promo code to redeem</h2>
							<Box mt={2}>
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<input
											placeholder='Promo Code'
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												width: '100%',
												borderRadius: 5,
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Button
											style={{
												background: 'rgb(253 121 14) ',
												width: '100%',
												color: 'white',
												padding: 10,
											}}>
											Apply
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Box> */}
						{matches ? <FlightSummaryForm /> : ''}
					</Grid>
				</Grid>
			</Container>
			<Footer />
			{loading || loadingReturn ? <LoaderModal /> : ''}
		</div>
	);
};

export default ReviewFlight;
