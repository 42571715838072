import React, { Fragment, useState, useCallback } from 'react';
import './Navbar.css';
import arrowDown from '../../../assets/images/arrowDown.svg';
import { Box } from '@mui/material';
import emal from '../../../assets/images/emal.svg';
import call from '../../../assets/images/Call.svg';
import event from '../../../assets/images/Eventnote.svg';
import fb from '../../../assets/images/fb.svg';
import In from '../../../assets/images/In.svg';
import logo from '../../../assets/images/FAREEASE03.png';
import logo2 from '../../../assets/images/FAREEASE02.png';
import Men from './Submenu/Men';
import Women from './Submenu/Women';
import Kids from './Submenu/Kids';
import Home from './Submenu/Home';
import Beauty from './Submenu/Beauty';
import Studio from './Submenu/Studio';
import Profile from './Submenu/Profile';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LoginModal from '../LoginModal.js';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedHeaderTab } from '../../../reducers/UiReducer.js';

const Navbar = ({ user }) => {
	const [Menu1, setMenu1] = useState('hidden');
	const [Menu2, setMenu2] = useState('hidden');
	const [Menu3, setMenu3] = useState('hidden');
	const [Menu4, setMenu4] = useState('hidden');
	const [Menu5, setMenu5] = useState('hidden');
	const [Menu6, setMenu6] = useState('hidden');
	const [Menu7, setMenu7] = useState('hidden');
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);
	const [show4, setShow4] = useState(false);
	const [show5, setShow5] = useState(false);
	const [show6, setShow6] = useState(false);
	const [show7, setShow7] = useState(false);
	const [open, setOpen] = React.useState(false);
	const { headerTab } = useSelector((state) => state.ui);
	const { agentInfo, walletagent } = useSelector((state) => state.agent);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	console.log('location', location);
	const handleOpen = () => {
		if (agentInfo?.status === 200) {
			navigate('/dashboard');
		} else {
			setOpen(true);
		}
	};
	const handleClose = () => setOpen(false);
	const callback = useCallback((Menu, v) => {
		setMenu1(Menu);
		setShow1(v);
	}, []);
	const Callbackmenu2 = useCallback((Menu2, v) => {
		setMenu2(Menu2);
		setShow2(v);
	}, []);
	const Callbackmenu3 = useCallback((Menu3, v) => {
		setMenu3(Menu3);
		setShow3(v);
	}, []);
	const Callbackmenu4 = useCallback((Menu4, v) => {
		setMenu4(Menu4);
		setShow4(v);
	}, []);
	const Callbackmenu5 = useCallback((Menu5, v) => {
		setMenu5(Menu5);
		setShow5(v);
	}, []);
	const Callbackmenu6 = useCallback((Menu6, v) => {
		setMenu6(Menu6);
		setShow6(v);
	}, []);
	const Callbackmenu7 = useCallback((Menu7, v) => {
		setMenu7(Menu7);
		setShow7(v);
	}, []);

	return (
		<Fragment>
			<div
				// sticky
				// top-0
				className='2xl:w-[100%] xl:w-[100%] lg:w-[100%] mx-auto max-w-[100%] h-[60px]  contenthide z-10 '
				style={{ padding: 10, marginBottom: 20 }}>
				<div className='2xl:grid xl:grid grid-cols-2 lg:flex h-full mx-6  '>
					<ul className=' h-full flex font1 font-semibold text-base md:text-[14px] text-[#282c3f] tracking-[.3px] uppercase'>
						<Link className='w-max px-3 flex items-stretch' to='/'>
							<li
								className='w-max flex items-stretch'
								style={{ alignItems: 'center' }}>
								<img
									src={location?.pathname === '/' ? logo : logo2}
									style={{
										cursor: 'pointer',
										height: '40px',
										// marginLeft: '10px',
									}}
								/>
							</li>
						</Link>
						{/* <Link
							className='w-max px-3 flex items-stretch'
							to='/'
							onClick={() => dispatch(setSelectedHeaderTab('home'))}>
							<li
								className={`w-max flex  justify-center items-center border-4 border-transparent cborder ${
									headerTab === 'home' ? 'cborder1' : ''
								}`}>
								<h2 className='px-3 '>HOME</h2>
							</li>
						</Link> */}
						{/* <Link
							className='w-max  flex items-stretch'
							to='/'
							onClick={() => dispatch(setSelectedHeaderTab('tour'))}>
							<li
								className={`w-max flex  justify-center items-center border-4 border-transparent cborder ${
									headerTab === 'tour' ? 'cborder2' : ''
								}`}>
								<h1 className='px-3'>TOUR</h1>
							</li>
						</Link> */}
						{/* <Link
							className='w-max  flex items-stretch'
							to='/flight'
							onClick={() => dispatch(setSelectedHeaderTab('flight'))}>
							<li
								className={`w-max flex  justify-center items-center border-4 border-transparent cborder ${
									headerTab === 'flight' ? 'cborder3' : ''
								}`}>
								<h1 className='px-3'>FLIGHT</h1>
							</li>
						</Link> */}
						{/* <Link
							className='w-max  flex items-stretch'
							to='/hotel'
							onClick={() => dispatch(setSelectedHeaderTab('hotel'))}>
							<li
								className={`w-max flex  justify-center items-center border-4 border-transparent cborder ${
									headerTab === 'hotel' ? 'cborder4' : ''
								}`}>
								<h1 className='px-3'>HOTEL</h1>
							</li>
						</Link> */}
						{/* <Link
							className='w-max  flex items-stretch'
							to='/busbooking'
							onClick={() => dispatch(setSelectedHeaderTab('bus'))}>
							<li
								className={`w-max flex  justify-center items-center border-4 border-transparent cborder ${
									headerTab === 'bus' ? 'cborder4' : ''
								}`}>
								<h1 className='px-3'>BUS</h1>
							</li>
						</Link> */}
						{/* <li
							onClick={() => dispatch(setSelectedHeaderTab('dest'))}
							style={{ cursor: 'pointer' }}
							className={`w-max flex  justify-center items-center border-4 border-transparent cborder ${
								headerTab === 'dest' ? 'cborder5' : ''
							}`}>
							<h1 className='px-3'>DESTINATION</h1>
						</li> */}
						{/* <Link
							className='w-max  flex items-stretch'
							to='/contactus'
							onClick={() => dispatch(setSelectedHeaderTab('contact'))}>
							<li
								className={`w-max flex  justify-center items-center border-4 border-transparent cborder ${
									headerTab === 'contact' ? 'cborder6' : ''
								}`}>
								<h1 className='px-3 relative'>CONTACT</h1>
							</li>
						</Link> */}
					</ul>

					<div className=' h-full justify-center items-center '>
						<ul className='flex float-right  h-full  text-[#282c3f] tracking-[.3px] sent'>
							{/* <li className='w-max flex justify-center items-center font1 font-semibold capitalize no-underline text-sm border-4 border-transparent '>
								<h1 className='px-3 text-center text-xs relative'>
									<div style={{ display: 'flex' }}>
										<span className='block'>English</span>{' '}
										<img
											src={arrowDown}
											style={{
												cursor: 'pointer',
												marginLeft: 5,
											}}
										/>
									</div>
								</h1>
							</li> */}
							{/* <li className='w-max flex justify-center items-center font1 font-semibold capitalize no-underline text-sm border-4 border-transparent '>
								<h1 className='px-3 text-center text-xs relative'>
									
									<div style={{ display: 'flex' }}>
										<span className='block'>USD</span>{' '}
										<img
											src={arrowDown}
											style={{
												cursor: 'pointer',
												marginLeft: 5,
											}}
										/>
									</div>
								</h1>
							</li> */}
							<li className='w-max flex justify-center items-center font1 font-semibold capitalize no-underline text-sm border-4 border-transparent'>
								<h1
									style={{ cursor: 'pointer' }}
									className='px-3 text-center text-xs relative'
									onClick={handleOpen}>
									{agentInfo?.status === 200 ? (
										<span
											className='block'
											style={{ color: 'white', fontWeight: 'bold' }}>
											Hi, {agentInfo?.result?.result?.name}
											<br />
											<span
												style={{
													color: 'white',
													fontWeight: 'bold',
												}}>{`Available Balance ₹ ${walletagent?.result?.balanceAmount}`}</span>
										</span>
									) : (
										<Box
											style={{
												backgroundColor: 'rgb(253 121 14) ',
												padding: 15,
												borderRadius: 25,
												color: 'white',
											}}>
											<span className='block'>Login / Register</span>
										</Box>
									)}
								</h1>
							</li>
							{/* <li className='w-max flex justify-center items-center font1 font-semibold capitalize no-underline text-sm border-4 border-transparent '>
								<Link to='/mywishlist'>
									<h1 className='px-3 text-xs text-center relative'>
										{' '}
										<span className='text-lg absolute -top-5 left-1/3'>
											<BsHeart />
										</span>
										Wishlist
									</h1>
								</Link>
							</li>
							<li className='w-max flex justify-center items-center font1 font-semibold capitalize no-underline text-sm border-4 border-transparent '>
								<Link to='/cart'>
									{' '}
									<h1 className='px-3 text-xs text-center relative'>
										<span className='text-lg absolute -top-5 left-1/3'>
											<BsHandbag />
										</span>
										Bag
									</h1>
								</Link>
							</li> */}
						</ul>
					</div>
				</div>
				<Men show={show1} CMenu={Menu1} parentCallback={callback} />
				<Women show={show2} CMenu={Menu2} parentCallback={Callbackmenu2} />
				<Kids show={show3} CMenu={Menu3} parentCallback={Callbackmenu3} />
				<Home show={show4} CMenu={Menu4} parentCallback={Callbackmenu4} />
				<Beauty show={show5} CMenu={Menu5} parentCallback={Callbackmenu5} />
				<Studio show={show6} CMenu={Menu6} parentCallback={Callbackmenu6} />
				<Profile
					user={user}
					show={show7}
					CMenu={Menu7}
					parentCallback={Callbackmenu7}
				/>
				<LoginModal
					open={open}
					handleOpen={handleOpen}
					handleClose={handleClose}
					setOpen={setOpen}
				/>
			</div>
		</Fragment>
	);
};

export default Navbar;
