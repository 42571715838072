import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Toolbar from '@mui/material/Toolbar';
import Person3Icon from '@mui/icons-material/Person3';
import logo from '../../assets/images/FAREEASE03.png';
import KeyIcon from '@mui/icons-material/Key';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from 'react-router-dom';
import { agentloginSuccess } from '../../reducers/AgentReducer';
import { useDispatch } from 'react-redux';

export default function SideBar() {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	console.log('location', location);
	const DrawerList = (
		<Box sx={{ width: 250 }} role='presentation'>
			<Toolbar>
				<Box
					onClick={() => navigate('/')}
					style={{
						background: 'white',
						padding: 15,
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 5,
						cursor: 'pointer',
					}}>
					<img src={logo} />
				</Box>
			</Toolbar>
			<Divider style={{ border: '1px solid grey' }} />
			<Divider />
			<List>
				{[
					'Dashboard',
					'Profile',
					'Manage Markup',
					'Change Password',
					'Transaction Histroy',
					'Booking Flight',
					'Booking Bus',
				].map((text, index) => (
					<>
						<ListItem
							key={text}
							disablePadding
							style={{
								padding: 4,
								backgroundColor:
									index === 0 && location.pathname === '/dashboard'
										? '#d3d3d340'
										: index === 1 && location.pathname === '/profile'
										? '#d3d3d340'
										: index === 2 && location.pathname === '/managemarkup'
										? '#d3d3d340'
										: index === 3 && location.pathname === '/changepassword'
										? '#d3d3d340'
										: index === 4 && location.pathname === '/transhistory'
										? '#d3d3d340'
										: index === 5 && location.pathname === '/bookinghistory'
										? '#d3d3d340'
										: index === 6 && location.pathname === '/bookinghistorybus'
										? '#d3d3d340'
										: '',
							}}
							onClick={() =>
								navigate(
									index === 0
										? '/dashboard'
										: index === 1
										? '/profile'
										: index === 2
										? '/managemarkup'
										: index === 3
										? '/changepassword'
										: index === 4
										? '/transhistory'
										: index === 5
										? '/bookinghistory'
										: index === 6
										? '/bookinghistorybus'
										: ''
								)
							}>
							<ListItemButton>
								<ListItemIcon>
									{index === 0 ? (
										<DashboardIcon style={{ color: 'white' }} />
									) : index === 1 ? (
										<Person3Icon style={{ color: 'white' }} />
									) : index === 2 ? (
										<Person3Icon style={{ color: 'white' }} />
									) : index === 3 ? (
										<KeyIcon style={{ color: 'white' }} />
									) : index === 4 ? (
										<ManageHistoryIcon style={{ color: 'white' }} />
									) : index === 5 ? (
										<ManageHistoryIcon style={{ color: 'white' }} />
									) : index === 6 ? (
										<ManageHistoryIcon style={{ color: 'white' }} />
									) : (
										''
									)}
								</ListItemIcon>
								<ListItemText
									primary={text}
									style={{ color: 'white', fontSize: 14 }}
								/>
							</ListItemButton>
						</ListItem>
						{/* <Divider style={{ border: '1px solid grey' }} /> */}
					</>
				))}
			</List>
			<Divider />
			<List>
				{['Logout'].map((text, index) => (
					<ListItem
						key={text}
						disablePadding
						style={{ padding: 6 }}
						onClick={() => {
							navigate('/');
							sessionStorage.removeItem('agentInfo');
							dispatch(agentloginSuccess(null));
						}}>
						<ListItemButton>
							<ListItemIcon>
								<LogoutIcon style={{ color: 'white' }} />
							</ListItemIcon>
							<ListItemText primary={text} style={{ color: 'white' }} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	return (
		<Drawer
			variant='permanent'
			anchor='left'
			sx={{
				'& .MuiPaper-root': {
					background: '#232a45',
				},
				'& .MuiListItem-root:hover': {
					// 👇 Customize the hover bg color here
					backgroundColor: '#d3d3d340',
				},
			}}
			style={{ width: '17vw', flexShrink: 0 }}>
			{DrawerList}
		</Drawer>
	);
}
